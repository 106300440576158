import { http } from './config'
import authHeader from './auth-header';

export default {
     

  lista: (nome, cemiterio, page, pageSize) => {

    const params = {
         'pesquisa': nome,
         'cemiterio': cemiterio,
         'page': page,
         'size': pageSize
     }
     // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
     return http.get('/obitos/lista_falecido', {
         params: params,
         headers: authHeader() 
   });
   },



}
